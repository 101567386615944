<template lang="pug">
    modal(name="MJStats" class="mj-stats-modal-wrap" :adaptive="true" width="100%" height="100%")
        .mj-stats-modal
            ._wrap
                ._content
                    ._content-inner
                        perfect-scrollbar._form
                            ._close-btn(@click="$modal.hide('MJStats')")
                                svg-icon(icon-name="cross")._close-icon
                            ._group.-dates
                                input(v-model="startDate" value="" type="date")._days-input
                                input(v-model="endDate" value="" type="date")._days-input
                                ._notification(v-if="startDate && endDate") {{ notification }}
                            ._group(v-if="midjourneyCount")
                                ._group-title Midjourney 
                                    pre {{ midjourneyCount }}
                                ._group-list
                                    ._group-item(v-for="(error) in midjourney" v-if="error.count")
                                        span._group-item-title {{ localeMessages[error.name] }}
                                        span._group-item-value {{ error.count }} ({{ (error.count / midjourneyCount * 100).toFixed(1) }}%)
                            ._group(v-if="stableDiffusionCount")
                                ._group-title Stable Diffusion 
                                    pre {{ stableDiffusionCount }}
                                ._group-list
                                    ._group-item(v-for="(error) in stableDiffusion" v-if="error.count")
                                        span._group-item-title {{ localeMessages[error.name] }}
                                        span._group-item-value {{ error.count }} ({{ (error.count / stableDiffusionCount * 100).toFixed(1) }}%)
                            ._group
                                ._group-title Runway
                                    pre {{ runwayCount }}
                                ._group-list
                                    ._group-item(v-for="(error) in runway")
                                        span._group-item-title {{ localeMessages[error.name] }}
                                        span._group-item-value {{ error.count }} ({{ (error.count / runwayCount * 100).toFixed(1) }}%)
                            ._group(v-if="dalleCount")
                                ._group-title DALLE 
                                    pre {{ dalleCount }}
                                ._group-list
                                    ._group-item(v-for="(error) in dalle" v-if="error.count")
                                        span._group-item-title {{ localeMessages[error.name] }}
                                        span._group-item-value {{ error.count }} ({{ (error.count / dalleCount * 100).toFixed(1) }}%)
                            ._group(v-if="pikaCount")
                                ._group-title Pika 
                                    pre {{ pikaCount }}
                                ._group-list
                                    ._group-item(v-for="(error) in pika" v-if="error.count")
                                        span._group-item-title {{ localeMessages[error.name] }}
                                        span._group-item-value {{ error.count }} ({{ (error.count / pikaCount * 100).toFixed(1) }}%)
                            ._group(v-if="faceSwapCount")
                                ._group-title Face Swap 
                                    pre {{ faceSwapCount }}
                                ._group-list
                                    ._group-item(v-for="(error) in faceSwap" v-if="error.count")
                                        span._group-item-title {{ localeMessages[error.name] }}
                                        span._group-item-value {{ error.count }} ({{ (error.count / faceSwapCount * 100).toFixed(1) }}%)

                            ui-button(:text="btnText" @click.native="apply")._apply-btn

</template>

<script>
import UiButton from "../../ui/ui-button/ui-button.vue";
import multiselect from "vue-multiselect/src";
import getErrorMessagesQuery from "@/graphql/queries/getErrorMessages.query.graphql";
import getAiErrorsQuery from "@/graphql/queries/getAiErrors.query.graphql";
import gql from "graphql-tag";

export default {
    name: "MJStats",
    components: { UiButton, multiselect },
    data() {
        return {
            startDate: "",
            endDate: "",
            result: [],
            localeMessages: {
                "generated-by-stable-diffusion": "Сгенерировано",
                "generated-by-midjorney": "Сгенерировано",
                "generated-by-dalle": "Сгенерировано",
                "upscale-by-midjorney": "Апскейлов",
                "optimization-midjorney": "Зависло на оптимизации",
                "midjorney-moderator-error": "Ошибка модерации 1",
                "midjorney-moderator-error-1": "Ошибка модерации 2",
                "midjorney-moderator-error-2": "Ошибка модерации 3",
                "midjorney-single-image-error": "Ошибка с одной картинкой",
                "midjorney-image-filter-error": "Фильтр по изображению",
                "midjorney-unknown-style-error": "Ошибка неизвестного стиля",
                "midjorney-stress-error": "Большая нагрузка",
                "midjorney-unknown-error": "Неизвестная ошибка",
                "midjorney-params-position-error": "Параметры не в конце",
                "midjorney-sref-text-error": "Неверный атрибут в --sref",
                "midjorney-sref-error": "Ошибка в --sref",
                "midjorney-cref-text-error": "Неверный атрибут в --cref",
                "midjorney-cref-error": "Ошибка в --cref",
                "midjorney-image-timeout-error": "Большое изображение",
                "midjorney-duplicate-image-error": "2 одинаковых изображения",
                "midjorney-image-access-error": "Нет доступа к фото",
                "midjorney-something-wrong-error": "Something wrong",
                "generated-by-pika": "Сгенерировано",
                "pika-unknown-error": "Ошибок",
                "pika-generating": "Зависло на генерации",
                "face-swap-generating": "Зависло на генерации",
                "midjourney-describe": "Describe",
                "midjourney-invalid-image-link": "Не верная ссылка на картинку",
                "midjorney-purchase-error": "Закончились turbo часы",
                "midjourney-queue": "Зависло в очереди",
                "midjourney-captcha-needed": "Требуется пройти капчу",
                "face-swap-queue": "Зависло в очереди",
                "face-swap-detection-failed": "Не найдено лицо на аватарке",
                "face-swap-detection-target-failed":
                    "Не найдено лицо на целевом фото",
                "generated-by-face-swap": "Сгенерировано",
                "assistant-server-error": "Ошибка сервера",
                "assistant-limit-error": "Кончились лимиты",
                "assistant-prompt-error": "Ошибка в запросе",
                "midjorney-cw-sref-error": "Использование --cw без --sref",
                "midjorney-cw-cref-error": "Использование --cw без --cref",
                "dalle-moderation-error": "Ошибка модерации",
                "midjourney-repeat": "Отправлено на повторную генерацию",
                "midjourney-moderation-repeat": "Повтор из-за модерации",
                "midjourney-manual-key": "Ушло на ручную проверку",
                "midjourney-second-success": "Выполнено со второго раза",
                "runway-waiting": "Зависло на ожидании",
                "generated-by-runway": "Сгенерировано",
                "runway-error": "Ошибок",
            },
            messages: [
                "generated-by-stable-diffusion",
                "generated-by-midjorney",
                "generated-by-dalle",
                "upscale-by-midjorney",
                "optimization-midjorney",
                "midjorney-moderator-error",
                "midjorney-moderator-error-1",
                "midjorney-moderator-error-2",
                "midjorney-single-image-error",
                "midjorney-image-filter-error",
                "midjorney-unknown-style-error",
                "midjorney-stress-error",
                "midjorney-unknown-error",
                "midjorney-params-position-error",
                "midjorney-sref-text-error",
                "midjorney-sref-error",
                "midjorney-cref-text-error",
                "midjorney-cref-error",
                "midjorney-image-timeout-error",
                "midjorney-duplicate-image-error",
                "midjorney-image-access-error",
                "midjorney-something-wrong-error",
                "generated-by-pika",
                "pika-unknown-error",
                "pika-generating",
                "face-swap-generating",
                "midjourney-describe",
                "midjourney-invalid-image-link",
                "midjorney-purchase-error",
                "midjourney-queue",
                "face-swap-queue",
                "face-swap-detection-failed",
                "face-swap-detection-target-failed",
                "generated-by-face-swap",
                "midjorney-cw-sref-error",
                "midjorney-cw-cref-error",
                "dalle-moderation-error",
                "midjourney-repeat",
                "midjourney-moderation-repeat",
                "midjourney-manual-key",
                "midjourney-second-success",
                "runway-waiting",
                "generated-by-runway",
                "runway-error",
            ],
            isLoading: false,
        };
    },
    computed: {
        btnText: (state) =>
            state.isLoading ? "Данные загружаются..." : "Загрузить данные",
        notification: function () {
            let startDate = "";
            let endDate = "";

            if (this.startDate) {
                startDate = new Date(this.startDate)
                    .toLocaleString()
                    .split(",")[0];
            }

            if (this.endDate) {
                endDate = new Date(this.endDate).toLocaleString().split(",")[0];
            }

            return `Данные с ${startDate} 00:00:00 до ${endDate} 23:59:59`;
        },
        midjourney: (state) =>
            state.result
                .filter(
                    (t) =>
                        t.name.includes("midjorney") ||
                        t.name.includes("midjourney"),
                )
                .sort((a, b) => b.count - a.count),
        midjourneyCount: (state) =>
            state.midjourney.reduce((acc, value) => value.count + acc, 0),
        dalle: (state) =>
            state.result
                .filter((t) => t.name.includes("dalle"))
                .sort((a, b) => b.count - a.count),
        stableDiffusion: (state) =>
            state.result
                .filter((t) => t.name.includes("stable-diffusion"))
                .sort((a, b) => b.count - a.count),
        dalleCount: (state) =>
            state.stableDiffusion.reduce((acc, value) => value.count + acc, 0),
        stableDiffusionCount: (state) =>
            state.dalle.reduce((acc, value) => value.count + acc, 0),
        pika: (state) =>
            state.result
                .filter((t) => t.name.includes("pika"))
                .sort((a, b) => b.count - a.count),
        pikaCount: (state) =>
            state.pika.reduce((acc, value) => value.count + acc, 0),
        runway: (state) =>
            state.result
                .filter((t) => t.name.includes("runway"))
                .sort((a, b) => b.count - a.count),
        runwayCount: (state) =>
            state.runway.reduce((acc, value) => value.count + acc, 0),
        faceSwap: (state) =>
            state.result
                .filter((t) => t.name.includes("face-swap"))
                .sort((a, b) => b.count - a.count),
        faceSwapCount: (state) =>
            state.faceSwap.reduce((acc, value) => value.count + acc, 0),
    },
    mounted() {},
    methods: {
        async apply() {
            this.result = [];
            this.isLoading = true;
            await this.getErrors(this.messages);
            await this.getAiErrors();
            this.isLoading = false;
        },
        async getErrors(error) {
            await this.$apollo
                .query({
                    query: gql(getErrorMessagesQuery),
                    variables: {
                        error,
                        days: [
                            `${this.startDate} 00:00:00`,
                            `${this.endDate} 23:59:59`,
                        ],
                    },
                    fetchPolicy: "no-cache",
                    client: "chatClient",
                })
                .then((r) => {
                    this.result = r.data.getErrorMessages;
                });
        },
        async getAiErrors() {
            await this.$apollo
                .query({
                    query: gql(getAiErrorsQuery),
                    variables: {
                        date: [
                            `${this.startDate} 00:00:00`,
                            `${this.endDate} 23:59:59`,
                        ],
                    },
                    client: "chatClient",
                })
                .then((r) => {
                    this.result.push(...r.data.getAiErrors);
                });
        },
    },
};
</script>

<style lang="scss" src="./mj-stats.scss"></style>
